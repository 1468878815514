/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React from 'react';
import css from './DealerNudge.APP_TARGET.scss';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getUserData } from 'Selectors/user';
import { elapsedTime } from './elapsedtime';
import classNames from 'classnames';
import { FormattedMessage as Translation, injectIntl } from 'react-intl';

function DealerNudge({ user, handleUpdateShowroomClick, handleSkipShowroomClick }) {
    const dealerNudgeTime = elapsedTime(user?.showroom?.last_skipped_at);

    return (
        <div>
            {
                user?.showroom_enabled && user?.showroom_status === 'initiated' && (isNaN(dealerNudgeTime) || dealerNudgeTime > 1)
                && <div className={ css.dealerNudgeTop }>
                    <div className={ css.dealerPopUp } data-aut-id="onlineShowroomPopup">
                        <div className={ css.dealerInfo }>
                            <Translation id="nudge_text" values={ { value: user?.name } } />
                        </div>
                        <div className={ classNames(css.dealerButtonWrapper, css.dealerInfo) }>
                            <button data-aut-id="updateShowroomPopupButton" className={ css.dealerBtns } onClick={ handleUpdateShowroomClick }><Translation id="update_showroom" defaultMessage="Update Showroom" /></button>
                            <button data-aut-id="skipPopupButton" className={ css.dealerBtns } onClick={ handleSkipShowroomClick }><Translation id="contact_me_skip_button" defaultMessage="Skip" /></button>
                        </div>
                    </div>
                </div>}
        </div>

    );
}

DealerNudge.propTypes = {
    user: PropTypes.object,
    handleUpdateShowroomClick: PropTypes.func,
    handleSkipShowroomClick: PropTypes.func
};

export const mapStateToProps = state => {
    return {
        user: getUserData(state)
    };
};

export default compose(
    connect(mapStateToProps),
    injectIntl
)(DealerNudge);
